import { useState, useEffect } from "react";

import {
	EXCLUDED_GREENHOUSE_FIELD_TYPES,
	FIXED_FIELDS_FILES,
	FIXED_FIELDS_TEXT,
} from "@/constants/greenhouse";
import type { PartialJob } from "@/context/jobs";
import { endpoints } from "@/endpoints";

const getGtmEventType = (job: PartialJob) => {
	/**
	 * We are checking both department id AND a rough name comparision here as it
	 * is possible the recruiting team could restructure things in Greenhouse,
	 * causing the id to change, and we would very likely not be informed of this.
	 * Just trying to mitigate that here.
	 */

	// Franchise/LiPa/Licence Partner jobs
	if (
		job?.departments?.some(
			({ id, name }) => id === 4030197101 || name.toLowerCase().includes("franchise")
		)
	) {
		return "PARTNER_APPLICATION";
	}

	// Makler/Real Estate Agent jobs
	if (
		job?.departments?.some(
			({ id, name }) => id === 4029541101 || name.toLowerCase().includes("real estate agent")
		)
	) {
		return "AGENT_APPLICATION";
	}

	// Everything else.
	return "JOB_APPLICATION";
};

export const useJob = (selectedJobId: string) => {
	const [jobQuestions, setJobQuestions] = useState([]);
	const [gtmEventType, setGtmEventType] = useState<string>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (!selectedJobId) return;

		const fetchJobData = async () => {
			setLoading(true);
			setError(null);
			try {
				// https://developers.greenhouse.io/job-board.html#retrieve-a-job
				const response = await fetch(
					endpoints.greenhouse.job.get(selectedJobId, false, true)
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const data = await response.json();
				let questions = data?.questions;

				// We want to filter out some of the questions as we want to handle
				// them manually e.g. any fields for files, and the standard fields
				if (questions?.length) {
					questions = questions.filter(
						item =>
							!item.fields.some(
								field =>
									EXCLUDED_GREENHOUSE_FIELD_TYPES.includes(field.type) ||
									[...FIXED_FIELDS_FILES, ...FIXED_FIELDS_TEXT].includes(
										field.name
									)
							)
					);
				}
				setGtmEventType(getGtmEventType(data));
				setJobQuestions(questions || []);
			} catch (error) {
				setError(error.message);
				setJobQuestions([]);
			} finally {
				setLoading(false);
			}
		};

		fetchJobData();
	}, [selectedJobId]);

	return { gtmEventType, jobQuestions, loading, error };
};
